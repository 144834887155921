/** @jsx jsx */
import React from 'react'
import { css, jsx } from '@emotion/react'
import Layout from '../components/Layout'
import ThemeWrapper from '../components/Theme'
import { Column, YouTubeAsBackground, ColumnWrap, ColumnWrapper, Image, Text, Title, Slideshow, TitleMain, Subtitle, Button, SeparateLine, SeparateLineWrap } from '@swp/components'
import SiteHeader from '../components/SiteHeader'

export default function Page(props) {
  return (
    <ThemeWrapper>
      <Layout >
        <SiteHeader set="" currentLanguage={undefined} />

        <Column className="--full pb--60 pt--60" name={"iv8dz1lix2e"} fullscreen={true}>
          <YouTubeAsBackground autoplay loop modestbranding mute controls={false} embedId={"L3374C3OyrY"} transformations={{"brightness":67,"contrast":110,"overlay":"#000000","overlayAlpha":25}} />
          
          <ColumnWrap className="column__flex --center el--1 flex--center" columns={"1"}>
            
            <ColumnWrapper >
              
              <Image style={{"maxWidth":678,"marginTop":18}} sizes="(max-width: 1439px) 100vw, 1440px" srcSet={"https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=1400x_.png 1400w, https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=2000x_.png 2000w"} alt={""} src={"https://cdn.swbpg.com/t/40863/f706ea66794f43f3afca6d8084774d12_s=860x_.png"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="--center" style={{"backgroundColor":"#040404","paddingBottom":57,"marginTop":0,"paddingTop":130}} name={"55u1cagf2ky"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper className="--center">
              
              <Text className="text-box" style={{"maxWidth":858}} content={"<span style=\"color: var(--color-blend--50);\">Užijte si výjimečná vína, které vás přenesou do světa vítězství a úspěchů.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Column className="pt--60" name={"289f3bvfy6z"} style={{"backgroundColor":"#040404","marginTop":0,"marginBottom":0,"paddingBottom":186}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72 ls--008 lh--1" content={"<span style=\"color: var(--white);\">vína&nbsp;<span style=\"font-style: italic;\">champions&nbsp;</span></span><br>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":781}} content={"<span style=\"color: var(--color-blend--50);\">Vína fotbalových šampionů, exkluzivně dovezená panem Jankulovským, jsou nyní k dispozici i pro vás. Pečlivě vybraný sortiment, který spojuje špičkovou kvalitu a sportovní vášeň, vám přináší autentický zážitek přímo z vinařských regionů, které jsou oblíbené mezi těmi nejlepšími fotbalisty.</span>"}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow style={{"marginBottom":0,"paddingBottom":0}} name={"yqfp8u5rf9"} autoplay={false} infinite={false}>
          
          <Column className="pb--60" style={{"backgroundColor":"#040404","paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
              
              <ColumnWrapper style={{"paddingBottom":0}}>
                
                <Image style={{"maxWidth":207}} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/40863/9788cdaaa1894a1d9e54f54ae8d624f3_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/9788cdaaa1894a1d9e54f54ae8d624f3_s=350x_.png"}>
                </Image>

                <TitleMain className="title-box fs--48 w--300 ls--008 lh--08" style={{"maxWidth":320}} content={"<span style=\"color: var(--color-dominant);\">JANKULOVSKI</span>"}>
                </TitleMain>

                <Subtitle className="subtitle-box subtitle-box--center ls--008" style={{"maxWidth":1000,"marginTop":8}} content={"<span style=\"font-style: italic; color: var(--color-dominant);\">Rosso Salento I.G.P. Malvasia Merlot</span>"}>
                </Subtitle>

              </ColumnWrapper>

            </ColumnWrap>

            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"backgroundColor":"#040404","paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
              
              <ColumnWrapper style={{"paddingBottom":0}}>
                
                <Image style={{"maxWidth":207}} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/40863/1e3355aa4937476596623d187e355d37_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/1e3355aa4937476596623d187e355d37_s=350x_.png"}>
                </Image>

                <TitleMain className="title-box fs--48 w--300 ls--008 lh--08" style={{"maxWidth":320}} content={"<span style=\"color: var(--color-dominant);\">JANKULOVSKI</span>"}>
                </TitleMain>

                <Subtitle className="subtitle-box subtitle-box--center ls--008" style={{"maxWidth":1000,"marginTop":8}} content={"<span style=\"font-style: italic; color: var(--color-dominant);\">Rosso Salento I.G.P. Malvasia Merlot</span>"}>
                </Subtitle>

              </ColumnWrapper>

            </ColumnWrap>

            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
              
              <ColumnWrapper style={{"paddingBottom":0,"paddingTop":0}}>
                
                <Image style={{"maxWidth":207}} sizes="100vw" srcSet={"https://cdn.swbpg.com/t/40863/0b3dd6f1db96428fb4e357cf8e4cc61f_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/0b3dd6f1db96428fb4e357cf8e4cc61f_s=350x_.png"}>
                </Image>

                <TitleMain className="title-box fs--48 w--300 ls--008 lh--08" style={{"maxWidth":320}} content={"<span style=\"color: var(--color-dominant);\">JANKULOVSKI</span>"}>
                </TitleMain>

                <Subtitle className="subtitle-box subtitle-box--center ls--008" style={{"maxWidth":1000,"marginTop":8}} content={"<span style=\"font-style: italic; color: var(--color-dominant);\">Rosso Salento I.G.P. Malvasia Merlot</span>"}>
                </Subtitle>

              </ColumnWrapper>

            </ColumnWrap>

            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
            </ColumnWrap>

            <ColumnWrap className="column__flex --center el--1 --full flex--top" columns={"1"} fullscreen={true}>
            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pb--60" style={{"paddingTop":0}} name={"viowi97pbzh"}>
          
          <ColumnWrap className="column__flex --center el--1" columns={"1"}>
            
            <ColumnWrapper >
              
              <Title className="title-box" content={"<span style=\"color: rgb(247, 247, 247);\">jak&nbsp;<span style=\"font-style: italic;\">vznikla</span> vína legend</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":650}} content={"„Víno legend“ představuje jedinečnou fúzi hlubokých hodnot, jako je přátelství, radost ze života a vášeň pro víno, které spojuje tým velkých šampionů. <br>Fabio Cordella, zakladatel tohoto projektu, je mužem sportu a potomkem historické rodiny vinařů, která od roku 1911 pěstuje vynikající vinice v Salentu."}>
              </Text>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <Slideshow name={"4sl94i9xvcy"}>
          
          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":290}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/2dc849b9910a405a89f26802c74aa62f_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/2dc849b9910a405a89f26802c74aa62f_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/2dc849b9910a405a89f26802c74aa62f_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/40863/2dc849b9910a405a89f26802c74aa62f_s=660x_.png"} lightbox={false} use={"page"} href={"/lugano"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/lugano\">Lugano</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":425}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/001179ec16164d83b0c774d4b031423e_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/001179ec16164d83b0c774d4b031423e_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/001179ec16164d83b0c774d4b031423e_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/40863/001179ec16164d83b0c774d4b031423e_s=660x_.png"} lightbox={false} use={"page"} href={"/terry"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/terry\">Terry</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":389}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/bcedd783144f4694ab042653a5199463_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/bcedd783144f4694ab042653a5199463_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/bcedd783144f4694ab042653a5199463_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/bcedd783144f4694ab042653a5199463_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/40863/bcedd783144f4694ab042653a5199463_s=860x_.png"} lightbox={false} use={"page"} href={"/candela"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/candela\">Candela</a>"}>
                </Title>

              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":338}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/8622cbf9d0bc4a50b19cdef3aaf3f3c8_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/8622cbf9d0bc4a50b19cdef3aaf3f3c8_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/8622cbf9d0bc4a50b19cdef3aaf3f3c8_s=860x_.png 860w"} alt={""} src={"https://cdn.swbpg.com/t/40863/8622cbf9d0bc4a50b19cdef3aaf3f3c8_s=660x_.png"} lightbox={false} use={"page"} href={"/amauri"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/amauri\">Amauri&nbsp;</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":359}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/71ce868c35804721ad9e02af39efaf12_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/71ce868c35804721ad9e02af39efaf12_s=350x_.png"} lightbox={false} use={"page"} href={"/ronaldinho"} target={null}>
                </Image>

                <Title className="title-box lh--12" content={"Ronaldinho<br>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":392}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/19a8c61225384908913e1be6389a9f71_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/19a8c61225384908913e1be6389a9f71_s=350x_.png"} lightbox={false} use={"page"} href={"/sneijder"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/sneijder\">Sneijder</a><br>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":402}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/d6783185b8a34ce4a403aada1462df83_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/d6783185b8a34ce4a403aada1462df83_s=350x_.png"} lightbox={false} use={"page"} href={"/carlos"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/carlos\">Carlos</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":407}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/fd3ffde59f1945b7a9c6a212529dbe2f_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/fd3ffde59f1945b7a9c6a212529dbe2f_s=350x_.png"} lightbox={false} use={"page"} href={"/materazzi"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/materazzi\">Materazzi\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":408}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/9c70d0e9c177461598b4d6a945cca055_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/9c70d0e9c177461598b4d6a945cca055_s=350x_.png"} lightbox={false} use={"page"} href={"/cesar"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/cesar\">Cesar\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":402}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/40863/7bb14202f6b14fea923c5fc238bbe9e7_s=660x_.png"} lightbox={false} use={"page"} href={"/galliani"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/galliani\">Galliani\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":407}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/40863/b22ff392173c4927a6165d5861d168d0_s=660x_.png"} lightbox={false} use={"page"} href={"/cafu"} target={null}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/cafu\">Cafu\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":408}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/40863/b862bc9ddd184336b8c574038c6a18ac_s=860x_.png"} lightbox={false} use={"page"} href={"/zamorano"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/zamorano\">Zamorano\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":402}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/88d66e87bdb44963a67450459fe70cff_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/88d66e87bdb44963a67450459fe70cff_s=350x_.png"} lightbox={false} use={"page"} href={"/jankulovski"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/jankulovski\">Jankulovski\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":407}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/a7897c26a2af4558bbc19ae48eafe68a_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/a7897c26a2af4558bbc19ae48eafe68a_s=350x_.png"} lightbox={false} use={"page"} href={"/frey"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/frey\">Frey\n\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":408}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/988831523bb6449082ba8950970cb8e7_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/988831523bb6449082ba8950970cb8e7_s=660x_.png 660w, https://cdn.swbpg.com/t/40863/988831523bb6449082ba8950970cb8e7_s=860x_.png 860w, https://cdn.swbpg.com/t/40863/988831523bb6449082ba8950970cb8e7_s=1400x_.png 1400w"} alt={""} src={"https://cdn.swbpg.com/t/40863/988831523bb6449082ba8950970cb8e7_s=860x_.png"} lightbox={false} use={"page"} href={"/ochoa"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/ochoa\">Ochoa\n\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

            </ColumnWrap>

          </Column>

          <Column className="pb--60" style={{"marginTop":0,"paddingTop":0}}>
            
            <ColumnWrap className="column__flex --center el--3 flex--center" columns={"3"}>
              
              <ColumnWrapper >
                
                <Image style={{"maxWidth":402}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/b2b54c1edcce4e0e88e0690ed8ecd87b_s=350x_.png 350w, https://cdn.swbpg.com/t/40863/b2b54c1edcce4e0e88e0690ed8ecd87b_s=660x_.png 660w"} alt={""} src={"https://cdn.swbpg.com/t/40863/b2b54c1edcce4e0e88e0690ed8ecd87b_s=660x_.png"} lightbox={false} use={"page"} href={"/amoroso"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/amoroso\">Amoroso</a><br>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":407}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/f328cc67e9274b2b820481f4c49bde7c_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/f328cc67e9274b2b820481f4c49bde7c_s=350x_.png"} lightbox={false} use={"page"} href={"/kuranyi"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/kuranyi\">Kuranyi\n\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
                
                <Image style={{"maxWidth":408}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={"https://cdn.swbpg.com/t/40863/01d0260a36164f68bf106d2b2c69375b_s=350x_.png 350w"} alt={""} src={"https://cdn.swbpg.com/t/40863/01d0260a36164f68bf106d2b2c69375b_s=350x_.png"} lightbox={false} use={"page"} href={"/chevanton"}>
                </Image>

                <Title className="title-box lh--12" content={"<a href=\"/chevanton\">Chevanton\n\n\n</a>"}>
                </Title>

              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

              <ColumnWrapper >
              </ColumnWrapper>

            </ColumnWrap>

          </Column>

        </Slideshow>


        <Column className="pb--60 pt--60" name={"informace"} style={{"backgroundColor":"var(--color-supplementary)"}} layout={"l13"}>
          
          <ColumnWrap className="column__flex --left el--1 flex--top" style={{"maxWidth":""}} columns={"1"}>
            
            <ColumnWrapper className="js-anim  --anim2 --anim-s5 --center" anim={"2"} animS={"5"} style={{"maxWidth":""}}>
              
              <Title className="title-box fs--72" style={{"maxWidth":950}} content={"<span style=\"color: var(--white);\">postup&nbsp;<span style=\"font-style: italic;\">objednávky</span>&nbsp;</span>"}>
              </Title>

              <Text className="text-box" style={{"maxWidth":534}} content={"<span style=\"color: var(--white);\">Klikněte a začněte psát text, tento prostor je určený pro text. V případě potřeby jde u bloku textu změnit barvu, změnit velikost písma, řádkování, nebo zarovnat text.</span>"}>
              </Text>

              <Button className="btn-box" content={"napsat si o víno"} use={"page"} href={"/kontakt"}>
              </Button>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>


        <SeparateLine className="pb--10 pt--10" name={"onokb7mutgi"}>
          
          <SeparateLineWrap >
          </SeparateLineWrap>

        </SeparateLine>


        <Column className="pb--40 pt--40" name={"paticka"} style={{"backgroundColor":"var(--color-supplementary)"}}>
          
          <ColumnWrap className="column__flex el--3 flex--center" anim={""} animS={"2"} style={{"maxWidth":1420}} columns={"3"}>
            
            <ColumnWrapper style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":366}} content={"<span style=\"color: var(--color-blend--50);\">Děkujeme, že jste si vybrali právě nás.&nbsp;</span>"}>
              </Text>

            </ColumnWrapper>

            <ColumnWrapper className="--center" style={{"maxWidth":""}}>
              
              <Title className="title-box ff--1" content={"<span style=\"color: var(--color-dominant);\">The Wine<br><span style=\"font-style: italic;\">of the</span> Champions<br></span>"}>
              </Title>

            </ColumnWrapper>

            <ColumnWrapper className="--right" style={{"maxWidth":""}}>
              
              <Text className="text-box" style={{"maxWidth":473}} content={"<span style=\"color: var(--color-blend--50);\">+420 797 811 2X0<br>info@vinalegend.com</span>"}>
              </Text>

              <Image style={{"maxWidth":24,"marginTop":13}} sizes="(max-width: 959px) 100vw, (max-width: 1439px) 33vw, 480px" srcSet={""} alt={"instagram"} src={"https://cdn.swbpg.com/o/40863/b29c23bfb6ac42fbb4d2db5a1aebd354.png"} lightbox={false} use={"url"} href={"https://www.instagram.com/jankul18/"} url={"https://www.instagram.com/jankul18/"}>
              </Image>

            </ColumnWrapper>

          </ColumnWrap>

        </Column>

      </Layout>
    </ThemeWrapper>
  )
}